import React from "react";
import ReactDOM from "react-dom";

import "./Sidebar.sass";

class Sidebar extends React.Component {
  render() {
    const { isOpen } = this.props;
    return ReactDOM.createPortal(
      <div
        className="sidebar"
        style={{
          width: isOpen ? 320 : 0,
        }}
      >
        <button className="close-sidebar" onClick={() => this.props.onClose()}>
          ✕
        </button>
        <div className="sidebar-container">{this.props.children}</div>
      </div>,
      document.body
    );
  }
}

export default Sidebar;
