import React, { Component } from "react";

import "./SortButton.sass";

class SortButton extends Component {
  render() {
    return (
      <>
        <button {...this.props}>{this.props.children}</button>
      </>
    );
  }
}

export default SortButton;
