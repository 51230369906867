import React, { Component } from "react";

import "./KeywordButtons.sass";

class KeywordButtons extends Component {
  state = {
    type: "",
  };

  handleSetType = (key) => {
    this.setState({ type: `${this.props.keyword}-${key}` });
    if (this.state.type === `${this.props.keyword}-${key}`) {
      this.setState({ type: "" });
    }
  };

  handleOnChange = (type) => {
    this.handleSetType(type);
  };

  handleSetInclude = (type) => {
    this.handleOnChange(type);
    this.props.setIncludes(this.props.keyword);
  };

  handleSetExclude = (type) => {
    this.handleOnChange(type);
    this.props.setExcludes(this.props.keyword);
  };

  render() {
    const { keyword, filters } = this.props;

    return (
      <div className="radio-container">
        <h3>{keyword}</h3>
        <div className="radio-wrapper">
          <div className="radio-control">
            <input
              value="+"
              name={`${keyword}-show`}
              className={`button-plus ${
                filters.include.includes(keyword) ? "selected" : ""
              }`}
              type="button"
              onClick={() => this.handleSetInclude("show")}
            />
          </div>
          <div className="radio-control">
            <input
              value="-"
              name={`${keyword}-hide`}
              className={`button-hide ${
                filters.exclude.includes(keyword) ? "selected" : ""
              }`}
              type="button"
              onClick={() => this.handleSetExclude("hide")}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default KeywordButtons;
