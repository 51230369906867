import React from "react";
import Parser from "html-react-parser";

import "./Header.sass";

export default function Header({ headerTitle, backgroundColor, color }) {
  return (
    <>
      {headerTitle && (
        <header
          style={{
            backgroundColor,
            color,
          }}
          className="header"
        >
          <div className="header-center">
            <span className="header-title">{Parser(headerTitle)}</span>
          </div>
        </header>
      )}
    </>
  );
}
