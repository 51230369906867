import React, { Component, Fragment } from "react";
import "./Events.sass";
import parseHTML from "../lib/parseHTML";
import PasswordModal from "./PasswordModal";

class EventItem extends Component {
  state = {
    show: false,
  };

  handleToggleShowModal = () => {
    this.setState((prevState) => ({ ...prevState, show: !prevState.show }));
  };

  handleEventClick = (link) => {
    if (this.props.password && this.props.password.length > 0) {
      this.handleToggleShowModal();
    } else {
      window.open(link);
    }
  };

  handleCheckPassword = (pwd) => {
    if (pwd === this.props.password) {
      window.open(this.props.link);
      this.setState((prevState) => ({ ...prevState, show: !prevState.show }));
      return true;
    }
    return false;
  };

  render() {
    const {
      id,
      title,
      description,
      thumbnail,
      link,
      dueDate,
      modal,
      eventsStyles,
      borderColor,
      borderPixelWidth,
    } = this.props;
    return (
      <Fragment>
        <li
          className="event"
          style={{
            backgroundColor: eventsStyles.card_background_color,
            border: borderPixelWidth && borderColor ? "solid" : "none",
            borderWidth: borderPixelWidth,
            borderColor: borderColor,
          }}
          onClick={() => this.handleEventClick(link)}
        >
          <div className="event-body">
            <img src={thumbnail} alt={title} />
            <div className="event-label">
              <div
                style={{ color: eventsStyles.card_title_color }}
                className="event-title"
              >
                {parseHTML(title)}
              </div>
              <div
                style={{ color: eventsStyles.card_description_color }}
                className="event-description"
              >
                {parseHTML(description)}
              </div>
            </div>
          </div>
          <div className="event-footer">
            <div className="event-footer_id">{id && `${id}`}</div>
            <div className="event-footer_duedate">{dueDate}</div>
          </div>
        </li>
        {this.state.show && (
          <PasswordModal
            isShow={this.state.show}
            title={modal.title}
            buttonText={modal.buttonText}
            onCheckPassword={this.handleCheckPassword}
            onClose={this.handleToggleShowModal}
          />
        )}
      </Fragment>
    );
  }
}

class Events extends Component {
  render() {
    const { events, eventsStyles, modal } = this.props;
    return (
      <ul className="events">
        {events?.map((i, k) => (
          <EventItem eventsStyles={eventsStyles} modal={modal} key={k} {...i} />
        ))}
      </ul>
    );
  }
}

export default Events;
